import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import About from "../components/about";
import Contact from "../components/contact";
import Carousel from "../components/carousel";
import Map from "../components/maps";
import meta from "../../metaTags";

import "../css/blogDetails.css";

export default function blogPost({ pageContext: model }) {
  return (
    <Layout>
      <SEO
        description={model.description}
        lang={meta.designation.lang}
        meta={meta.designation.meta}
        keywords={[...meta.designation.keywords, model.name, model.slug]}
        title={`${model.slug} | ${meta.designation.title}`}
        data={meta.designation.data}
        image={model.images[0]}
      />
      <div className="site-container blog-post">
        <div className="container">
          <div className="deskDetails">
            {model.images.length ? (
              <Carousel images={model.images} />
            ) : (
              <div className="no-image"></div>
            )}

            <About model={model} />
          </div>

          <Contact idenfier={model.slug} />
          <Map />
        </div>
      </div>
    </Layout>
  );
}
