import React from "react";

export default function About({ model }) {
  const { description, name } = model;
  const features = Object.keys(model.features);

  return (
    <div className="about section col-xs-12 col-lg-5" id="About">
      <div className="container">
        <div className="about-main row">
          {/* <div className="left col-md-5 col-lg-4 mb-3">
            <Img
              fluid={data.photo.fluid}
              objectFit="cover"
              objectPosition="top center"
            />
          </div> */}
          <div className="left">
            <div className="about-details">
              <h2 className="sub-position">{name}.</h2>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              /> */}
              <span>{description}</span>
              <ul className="details">
                {features.map((key) => {
                  const value = model.features[key];

                  if (!value || key === "others") {
                    return;
                  }

                  return (
                    <li>
                      <strong className="specs-column">{key}</strong>

                      <p className="specs-column">{value}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
