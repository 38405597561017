import React, { useEffect, useRef } from "react";
import BCarousel from "bootstrap/js/dist/carousel";
import "../css/carousel.css";

export default function Carousel({ images }) {
  const carousel = useRef(null);
  let myCarousel;

  useEffect(() => {
    myCarousel = new BCarousel(carousel.current, {
      interval: 7000,
    });

    myCarousel.cycle();
  }, []);

  return (
    <div
      className="carousel carousel-dark slide col-md-7"
      data-bs-ride="carousel"
      ref={carousel}
    >
      <div className="carousel-inner">
        {images.map((image, index) => {
          return (
            <div
              className={`carousel-item carousel_image ${
                index === 0 ? "active" : ""
              } `}
              key={index}
            >
              <img src={image} className="d-block w-100" alt="" />
            </div>
          );
        })}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="prev"
        onClick={() => myCarousel.prev()}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="next"
        onClick={() => myCarousel.next()}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
